import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Tooltip, Modal, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import moment from "moment";
function ComplexStatisticsCard({
  color,
  icon,
  title,
  count,
  displayIcon,
  iconReason = "",
  categories = [],
  unSatisfactoryReason = "",
  session_details = [],
  isAppliedJobCount,
  toltip = "",
}) {
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const handleClose = () => {
    setOpen(!open);
  };

  const pillSx = {
    display: "inline-block",
    padding: "0.2em 0.6em",
    borderRadius: "9999px",
    fontSize: "0.75rem",
    fontWeight: 500,
    textAlign: "center",
    color: "white",
  };

  // useEffect(() => {
  //   if (isAppliedJobCount) {
  //     setColumns([
  //       { Header: "Title", accessor: "title", align: "left" },
  //       { Header: "Applied On", accessor: "createdAt", align: "center" },
  //     ]);
  //   } else {
  //     setColumns([
  //       { Header: "Category Name", accessor: "category_name", align: "left" },
  //       { Header: "Score", accessor: "score", align: "center" },
  //       { Header: "Created At", accessor: "createdAt", align: "center" },
  //     ]);
  //   }

  //   const customAddAdmin =
  //     session_details &&
  //     session_details.length >= 1 &&
  //     session_details?.map((data, index) => {
  //       return {
  //         category_name: (
  //           <MDTypography
  //             component="a"
  //             href="#"
  //             variant="button"
  //             color="text"
  //             fontWeight="medium"
  //             key={index + 100}
  //           >
  //             {data?.category_name}
  //           </MDTypography>
  //         ),
  //         score: (
  //           <Typography
  //             component="a"
  //             href="#"
  //             variant="caption"
  //             color="textPrimary"
  //             fontWeight="medium"
  //           >
  //             <Box
  //               component="span"
  //               sx={{
  //                 ...pillSx,
  //                 backgroundColor: "#ab47bc",
  //                 color: "#fff",
  //               }}
  //             >
  //               {data?.score}
  //             </Box>
  //           </Typography>
  //         ),
  //         createdAt: (
  //           <MDTypography variant="caption" color="text" fontWeight="medium">
  //             {moment(data?.created_on).format("DD MMM YYYY")}
  //           </MDTypography>
  //         ),
  //       };
  //     });
  //   setRows(Array.isArray(customAddAdmin) ? customAddAdmin : []);
  // }, [session_details]);

  useEffect(() => {
    if (isAppliedJobCount) {
      setColumns([
        { Header: "Title", accessor: "title", align: "left" },
        { Header: "Applied On", accessor: "createdAt", align: "center" },
      ]);
      const appliedJobRows =
        session_details?.map((data, index) => ({
          title: (
            <MDTypography
              component="a"
              href="#"
              variant="button"
              color="text"
              fontWeight="medium"
              key={index}
            >
              {data?.title}
            </MDTypography>
          ),
          createdAt: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {moment(data?.created_on ? data?.created_on : data?.applied_on).format("DD MMM YYYY")}
            </MDTypography>
          ),
        })) || [];

      setRows(appliedJobRows);
    } else {
      setColumns([
        { Header: "Category Name", accessor: "category_name", align: "left" },
        { Header: "Score", accessor: "score", align: "center" },
        { Header: "Created At", accessor: "createdAt", align: "center" },
      ]);
      const defaultRows =
        session_details?.map((data, index) => ({
          category_name: (
            <MDTypography
              component="a"
              href="#"
              variant="button"
              color="text"
              fontWeight="medium"
              key={index + 100}
            >
              {data?.category_name}
            </MDTypography>
          ),
          score: (
            <Typography
              component="a"
              href="#"
              variant="caption"
              color="textPrimary"
              fontWeight="medium"
            >
              <Box
                component="span"
                sx={{
                  ...pillSx,
                  backgroundColor: "#ab47bc",
                  color: "#fff",
                }}
              >
                {data?.score}
              </Box>
            </Typography>
          ),
          createdAt: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {moment(data?.created_on).format("DD MMM YYYY")}
            </MDTypography>
          ),
        })) || [];

      setRows(defaultRows);
    }
  }, [isAppliedJobCount, session_details]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25}>
          <MDTypography
            display="block"
            variant="button"
            fontWeight="light"
            color="text"
            // sx={{
            //   whiteSpace: "nowrap",
            //   overflow: "hidden",
            //   textOverflow: "ellipsis",
            //   maxWidth: "130px",
            // }}
          >
            {title}
          </MDTypography>
          {count == "Unsatisfactory" ? (
            <Tooltip title={unSatisfactoryReason} arrow>
              <MDTypography variant="h4">{count}</MDTypography>
            </Tooltip>
          ) : (
            <MDTypography variant="h4">
              {toltip ? (
                <Tooltip title={toltip} arrow>
                  <span>{count}</span>
                </Tooltip>
              ) : (
                count
              )}
            </MDTypography>
          )}
          {categories && categories?.length > 0 && (
            <Tooltip
              title={
                <MDBox>
                  {categories?.map((category, index) => (
                    <MDTypography
                      sx={{ color: "#fff" }}
                      key={index}
                      variant="caption"
                      display="block"
                    >
                      {category}
                    </MDTypography>
                  ))}
                </MDBox>
              }
              arrow
            >
              <Icon fontSize="medium" color="inherit" sx={{ cursor: "pointer" }}>
                visibility
              </Icon>
            </Tooltip>
          )}
          {session_details &&
            session_details?.length > 0 &&
            (toltip ? (
              <Tooltip title={toltip} arrow>
                <Icon
                  fontSize="medium"
                  color="inherit"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  visibility
                </Icon>
              </Tooltip>
            ) : (
              <Icon
                fontSize="medium"
                color="inherit"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  handleClose();
                }}
              >
                visibility
              </Icon>
            ))}
          {displayIcon &&
            (displayIcon === "close" ? (
              <MDTypography variant="button" fontSize={12} fontWeight="light" color="text">
                <Tooltip title={iconReason} arrow>
                  <Icon fontSize="medium" color="error">
                    {displayIcon}
                  </Icon>
                </Tooltip>
              </MDTypography>
            ) : (
              <Icon fontSize="medium" color="success">
                {displayIcon}
              </Icon>
            ))}
        </MDBox>
      </MDBox>
      <Divider />
      {/* ///// stats detail modal   */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            p={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} mb={1}>
              {title}
            </MDTypography>
          </MDBox>
          <MDBox pt={1}>
            {session_details && session_details?.length >= 1 ? (
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
                checkBoxes={false}
              />
            ) : (
              <MDTypography
                component="h2"
                href="#"
                color="text"
                fontWeight="bold"
                sx={{
                  textAlign: "center",
                  my: 10,
                }}
              >
                YOU DON'T HAVE ANY Completed Practice Rounds
              </MDTypography>
            )}
          </MDBox>
        </Card>
      </Modal>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  displayIcon: PropTypes.string,
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
