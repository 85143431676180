import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const taskRepo = RepositoryFactory.get("task");

export const addNewTask = createAsyncThunk(
  "task/addNewTask",
  async ({ formData, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await taskRepo.createNewTask(formData);
      if (data.status_code === 200) {
        onSuccess();
        return data;
      }
    } catch (err) {
      console.log(err.response, "consolebyahsan");
      if (err.config.headers.Authorization) {
      }
      toast.error(err?.response?.data?.detail || "Something went wrong");
      return rejectWithValue(err);
    }
  }
);

export const getAllTasks = createAsyncThunk(
  "task/getAllTasks",
  async ({ payload, filter }, { rejectWithValue }) => {
    try {
      const { data } = await taskRepo.getAllTasks(payload, filter);
      if (data.status_code === 200) {
        return data.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.detail || "Something went wrong");
      return rejectWithValue(err);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "task/deleteTask",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await taskRepo.deleteTask(payload);
      if (data.status_code === 200) {
        onSuccess();
        return data.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.detail || "Something went wrong");
      return rejectWithValue(err);
    }
  }
);

export const getSingleTask = createAsyncThunk(
  "task/getSingleTask",
  async ({ payload, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await taskRepo.getSingleTask(payload);
      if (data.status_code == 200) {
        onSuccess(data.data);
        return data.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.detail || "Something went wrong");
      return rejectWithValue(error.message);
    }
  }
);

export const udpateTask = createAsyncThunk(
  "task/udpateTask",
  async ({ formData, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await taskRepo.udpateTask(formData);
      if (data.status_code == 200) {
        onSuccess();
        return data.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.detail || "Something went wrong");
      return rejectWithValue(error.message);
    }
  }
);
export const AddAssignUser = createAsyncThunk(
  "task/AddAssignUser",
  async ({ payload, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await taskRepo.AddAssignUser(payload);
      if (data.status_code == 200) {
        onSuccess();
        return data.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.detail || "Something went wrong");
      return rejectWithValue(error.message);
    }
  }
);
export const removeAssignUser = createAsyncThunk(
  "task/removeAssignUser",
  async ({ payload, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await taskRepo.removeAssignUser(payload);
      if (data.status_code == 200) {
        onSuccess();
        return data.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.detail || "Something went wrong");
      return rejectWithValue(error.message);
    }
  }
);

export const addComment = createAsyncThunk(
  "task/addComment",
  async ({ taskID, comment, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await taskRepo.addComment(taskID, comment);
      if (data?.status_code === 200) {
        onSuccess(data?.detail);
        return data.data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

export const udpateTaskStatus = createAsyncThunk(
  "task/udpateTaskStatus",
  async ({ taskID, status, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await taskRepo.udpateTaskStatus(taskID, status);
      if (data?.status_code === 200) {
        onSuccess();
        return data.data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      toast.error(err.response.data.detail || "Something went wrong");
      toast.error(err.data.detail || err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

const initialState = {
  loading: false,
  allTasks: [],
  getSingleTaskData: null,
  totalPages: 0,
  // Roles: [],
};

const TaskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addNewTask.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewTask.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewTask.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllTasks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTasks.fulfilled, (state, action) => {
        state.loading = false;
        state.allTasks = action.payload.data;
        state.totalPages = action.payload.total_pages;
      })
      .addCase(getAllTasks.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteTask.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteTask.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getSingleTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSingleTask.fulfilled, (state, action) => {
        state.loading = false;
        state.getSingleTaskData = action.payload;
      })
      .addCase(getSingleTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(udpateTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(udpateTask.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(udpateTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(AddAssignUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddAssignUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(AddAssignUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(removeAssignUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeAssignUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(removeAssignUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addComment.rejected, (state) => {
        state.loading = false;
      })
      .addCase(udpateTaskStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(udpateTaskStatus.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(udpateTaskStatus.rejected, (state) => {
        state.loading = false;
      });
  },
});
export default TaskSlice.reducer;
