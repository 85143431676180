import React, { useState, useEffect } from "react";
import Select from "react-select";

export default function DropDowns({
  rolesDetails,
  question,
  categoriesDetails,
  Questions,
  setQuestions,
  index,
  questionsType,
  isDisabled,
}) {
  console.log(rolesDetails, "rolesDetails");
  console.log(question, "question");
  const [matchingCategories, setMatchingCategories] = useState([]);

  useEffect(() => {
    const initialMatchingCategories = categoriesDetails
      .filter(
        (category) =>
          category.jobRoleName ===
          rolesDetails.find((role) => role?.value === question?.roleId)?.label
      )
      .map((category) => ({
        label: category.display_name,
        value: category.id,
      }));
    setMatchingCategories(initialMatchingCategories);
  }, [categoriesDetails, rolesDetails, question?.roleId]);

  const handleQuestionChange = (index, field, selectedOption) => {
    const updatedQuestions = Array.isArray(Questions) ? [...Questions] : { ...Questions };

    if (field === "roleId") {
      const matchedCategories = categoriesDetails
        .filter(
          (category) =>
            category.jobRoleName ===
            rolesDetails.find((role) => role?.value === selectedOption?.value)?.label
        )
        .map((category) => ({
          label: category.display_name,
          value: category.id,
        }));
      setMatchingCategories(matchedCategories);

      if (index !== "") {
        updatedQuestions[index]["role_category_id"] = "";
      } else {
        updatedQuestions["role_category_id"] = "";
      }
    }

    if (index !== "") {
      updatedQuestions[index][field] = selectedOption?.value;
    } else {
      updatedQuestions[field] = selectedOption?.value;
    }

    setQuestions(updatedQuestions);
  };

  return (
    <>
      <Select
        options={rolesDetails}
        value={rolesDetails?.find((role) => role?.value === question?.roleId) || null}
        onChange={(selectedOption) => handleQuestionChange(index, "roleId", selectedOption)}
        placeholder="Select Role"
        isClearable
        required
        isDisabled={isDisabled}
      />
      <div style={{ marginTop: "10px" }}>
        <Select
          options={matchingCategories}
          value={
            question?.role_category_id
              ? matchingCategories?.find(
                  (category) => category?.value === question?.role_category_id
                )
              : null
          }
          onChange={(selectedOption) =>
            handleQuestionChange(index, "role_category_id", selectedOption)
          }
          placeholder="Select Role Category"
          isClearable
          required
          isDisabled={isDisabled}
        />
      </div>
      <div style={{ marginTop: "10px" }}>
        <Select
          options={questionsType}
          value={questionsType?.find((role) => role?.value === question?.question_type_id) || null}
          onChange={(selectedOption) =>
            handleQuestionChange(index, "question_type_id", selectedOption)
          }
          placeholder="Select Question Category"
          isClearable
          required
          isDisabled={isDisabled}
        />
      </div>
    </>
  );
}
