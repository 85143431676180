import {
  Box,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import { getAllSkillGapAnalysis } from "store/Slices/BoardingSlice";
import { addSkillGapAnalysis } from "store/Slices/BoardingSlice";
import styled from "@emotion/styled";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useDispatch, useSelector } from "react-redux";
import { updateSkillGapAnalysis } from "store/Slices/BoardingSlice";
import { toast } from "react-toastify";
import { getJoinedUsers } from "store/Slices/usersSlice";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
        box-sizing: border-box;
        width: 100%;
        heigth: auto;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 8px 12px;
        border-radius: 8px;
        margin-bottom: 0;
        color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
        background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
        border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
        box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
    
        &:hover {
          border-color: ${blue[400]};
        }
    
        &:focus {
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
        }
    
        // firefox
        &:focus-visible {
          outline: 0;
        }
      `
);

const SkillGapModal = ({ isOpen, onClose, role, storeSelectedId, storeActivePagination }) => {
  const dispatch = useDispatch();
  const questionsArray = [
    {
      question:
        "What specific skills are you confident in that are directly related to your target role?",
      answer: "",
    },
    {
      question: "Which skills or knowledge areas do you feel least confident in for this role?",
      answer: "",
    },
    {
      question:
        "How do you prefer to learn new skills? (e.g., online courses, workshops, hands-on projects, mentoring)",
      answer: "",
    },
    {
      question:
        "Are there any certifications, industry tools, or technologies in your field that you’re unfamiliar with but want to learn?",
      answer: "",
    },
    {
      question: "What is your ultimate career goal, and how does this role fit into that path?",
      answer: "",
    },

    {
      question:
        "What challenges or obstacles have prevented you from learning new skills in the past?",
      answer: "",
    },
    {
      question:
        "Is there anything else you feel we should know to best help you reach your career goal?",
      answer: "",
    },
  ];
  const { singleSkillGap } = useSelector((state) => state.boarding);
  const { joinedUsers } = useSelector((state) => state.users);
  console.log(joinedUsers, "joinedUsers");
  const [questionsAnswers, setQuestionsAnswers] = useState(questionsArray);
  const [customerId, setCustumerId] = useState(null);
  console.log(customerId, "customerId");
  const [guestName, setGuestName] = useState("");
  const [isGuest, setIsGuest] = useState(false);
  const [name, setName] = useState("");
  const handleChange = (question, value) => {
    setQuestionsAnswers((prev) =>
      prev.map((item) => (item.question === question ? { ...item, answer: value } : item))
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isEmpty = questionsAnswers?.some((item) => item.answer == "") || !name;
    if (!isEmpty) {
      if (role == "create") {
        const payload = {
          name: name,
          questions_answers: questionsAnswers,
          customer_id: customerId || null,
          guest_name: guestName || null,
        };
        dispatch(
          addSkillGapAnalysis({
            payload,
            onSuccess: () => {
              toast.success("Skill Gap Analysis Added Successfully");
              setQuestionsAnswers(questionsArray);
              setName("");
              dispatch(getAllSkillGapAnalysis({ payload: storeActivePagination || 1 }));
            },
          })
        );
        onClose();
        console.log(payload, "payload");
      } else {
        const payload = {
          selectedId: storeSelectedId,
          name: name,
          questions_answers: questionsAnswers,
          customer_id: customerId || null,
          guest_name: guestName || null,
        };
        dispatch(
          updateSkillGapAnalysis({
            payload,
            onSuccess: () => {
              toast.success("Skill Gap Analysis Updated Successfully");
              setQuestionsAnswers(questionsArray);
              setName("");
              dispatch(getAllSkillGapAnalysis({ payload: storeActivePagination || 1 }));
            },
          })
        );
        onClose();
      }
    } else {
      toast.error("Please Answer and Email All the Questions");
    }
  };

  useEffect(() => {
    if (role == "create") {
      setName("");
      setCustumerId(null);
      setQuestionsAnswers(questionsArray);
    }
  }, [onClose]);

  useEffect(() => {
    if (singleSkillGap?.questions_answers) {
      setName(singleSkillGap?.name || "");
      if (singleSkillGap?.customer_id) {
        setIsGuest(false);
        setCustumerId(singleSkillGap?.customer_id);
        setGuestName(null);
      } else {
        setIsGuest(true);
        setCustumerId(singleSkillGap?.customer_id);
        setGuestName(singleSkillGap?.guest);
      }
      setQuestionsAnswers(singleSkillGap?.questions_answers);
    }
  }, [singleSkillGap]);

  // useEffect(())
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    boxShadow: 24,
    p: 4,
    maxHeight: "80vh",
    overflowY: "auto",
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} mb={1}>
            {role == "create" ? "Add" : "Update"} Skills Gap Analysis
          </MDTypography>
        </MDBox>
        <MDBox mx={1} p={1} mt={1}>
          <form>
            <MDBox mb={2}>
              <Box>
                <MDBox px={{ md: 1, lg: 10 }} pt={3} mb={1}>
                  <Typography as="h6" mb={1}>
                    Name
                  </Typography>
                  <MDInput
                    type="text"
                    label="Name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    // required
                    fullWidth
                  />
                </MDBox>
                <MDBox px={{ md: 1, lg: 10 }} pt={3} mb={1}>
                  <Typography as="h6" mb={1}>
                    Customer Id
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Customer Id</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Customer Id"
                      name="customerId"
                      value={isGuest ? "guest" : customerId}
                      onChange={(e) => {
                        if (e.target.value === "guest") {
                          setIsGuest(true);
                          setCustumerId(null);
                        } else {
                          setIsGuest(false);
                          setCustumerId(e.target.value);
                          setGuestName(null);
                        }
                      }}
                    >
                      <MenuItem value="guest">Guest User</MenuItem>
                      {joinedUsers.map((user) => (
                        <MenuItem key={user.customer_id} value={user.customer_id}>
                          {user.customer_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox>
                {isGuest && (
                  <MDBox px={{ md: 1, lg: 10 }} pt={3} mb={1}>
                    <Typography as="h6" mb={1}>
                      Name
                    </Typography>
                    <MDInput
                      type="text"
                      label="Guest Name"
                      value={guestName}
                      onChange={(e) => setGuestName(e.target.value)}
                      fullWidth
                    />
                  </MDBox>
                )}
                {questionsAnswers?.map((item, index) => (
                  <MDBox px={{ md: 1, lg: 10 }} pt={3} mb={1}>
                    <Typography as="h6" mb={1}>
                      {item.question}
                    </Typography>
                    <Textarea
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Answer here..."
                      value={item.answer}
                      onChange={(e) => handleChange(item.question, e.target.value)}
                      // onBlur={handleBlur}
                      fullWidth
                    />
                  </MDBox>
                ))}
              </Box>
            </MDBox>
            <MDBox mt={4} mb={1} sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
              <MDButton variant="gradient" color="info" onClick={onClose}>
                Cancel
              </MDButton>
              <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                {role == "create" ? "Submit" : "Update"}
              </MDButton>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </Modal>
  );
};

export default SkillGapModal;
