import Repository from "./Repository";
const CreateOnBoarding = "/organization-onboarding/create";
const UPDATE_ONBOARDING = "/organization-onboarding/update";
const GetOnBoarding = "/organization-onboarding/read";
const SKILLS_GAP = "/organization/generate_skill_gap_analysis";
const GET_ALL_SKILL_GAP = "/organization/get_skill_gap_analysis";
const CREATE_SKILL_GAP = "/organization/generate_skill_gap_analysis";
const UPDATE_SKILL_GAP = "/organization/update_skill_gap_analysis";
const DELETE_SKILL_GAP = "/organization/skill_gap_analysis/";
const SINGLE_SKILL_GAP = "/organization/skill_gap_analysis/";
const SKILL_GAP_EMAIL = "/send_email";

const OnBoardingRepository = {
  AddBoarding(payload) {
    return Repository.post(`${CreateOnBoarding}`, payload);
  },
  getBoarding() {
    return Repository.get(`${GetOnBoarding}`);
  },
  udpateOnBoard(payload) {
    return Repository.put(`${UPDATE_ONBOARDING}`, payload);
  },
  skillsGapAnalysis(payload) {
    return Repository.post(`${SKILLS_GAP}`, payload);
  },
  getAllSkillGapAnalysis(payload) {
    return Repository.get(`${GET_ALL_SKILL_GAP}?page=${payload}`);
  },
  addSkillGapAnalysis(payload) {
    console.log(payload, "payload");
    const data = {
      questions_answers: payload.questions_answers,
    };
    if (payload.guest_name != null) {
      return Repository.post(
        `${CREATE_SKILL_GAP}?name=${payload.name}&guest=${payload.guest_name}`,
        data
      );
    } else if (payload.customer_id != null) {
      return Repository.post(
        `${CREATE_SKILL_GAP}?name=${payload.name}&customer_id=${payload.customer_id}`,
        data
      );
    } else {
      return Repository.post(`${CREATE_SKILL_GAP}?name=${payload.name}`, data);
    }
  },
  updateSkillGapAnalysis(payload) {
    const data = {
      questions_answers: payload.questions_answers,
    };
    if (payload.guest_name != null) {
      return Repository.put(
        `${UPDATE_SKILL_GAP}/${payload.selectedId}?name=${payload.name}&guest=${payload.guest_name}`,
        data
      );
    } else if (payload.customer_id != null) {
      return Repository.put(
        `${UPDATE_SKILL_GAP}/${payload.selectedId}?name=${payload.name}&customer_id=${payload.customer_id}`,
        data
      );
    } else {
      return Repository.put(`${UPDATE_SKILL_GAP}/${payload.selectedId}?name=${payload.name}`, data);
    }
  },
  deleteSkillGapAnalysis(payload) {
    return Repository.delete(`${DELETE_SKILL_GAP}${payload}`);
  },
  getSingleSkillGap(payload) {
    return Repository.get(`${SINGLE_SKILL_GAP}${payload}`);
  },
  sendSkillGapReport(payload) {
    const formData = new FormData();
    formData.append("subject", payload.subject);
    formData.append("recipient_email", payload.recipient_email);
    formData.append("body", payload.body);
    formData.append("attachments", payload.attachments, "SkillGapAnalysisReport.pdf");
    return Repository.post(`${SKILL_GAP_EMAIL}`, formData);
  },
};

export default OnBoardingRepository;
